.ns-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.ns-question-title {
  font-size: rem(32px);
  font-weight: bold;
  margin-top: rem(4px);
  @media (max-width: 576px) {
    margin: rem(4px) 0 rem(25px);
  }
}

.ns-opacity-50 {
  opacity: 0.5;
}

.ns-purple-400 {
  color: #984B61;
}

.ns-orange-400 {
  color: #F5862c;
}