.ns-checkbox-wrapper {
  display: inline;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

input {
  border: none;
  font-size: rem(14px);
  line-height: rem(17px);
  padding: rem(8px) 0;
  border-bottom: 1px solid #a8a8a8;
  font-family: 'Open Sans', sans-serif;
  &[type=text] {
    min-width: rem(180px);
  }
}