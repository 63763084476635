@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap&subset=latin-ext');

* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: rem(14px);
  font-family: $font-family-base;
  background-color: $white;
}

.font-playfair {
  font-family: 'Playfair Display', serif;
}